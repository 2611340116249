import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PrivaBffLoggedInUserGuard } from '@priva/auth/current-user';

const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('./app-main.component').then((m) => m.AppMainComponent),
        canActivate: [PrivaBffLoggedInUserGuard],
        children: [
            {
                path: '',
                loadComponent: () =>
                    import(
                        './features/employee-management/employee-overview/employee-overview.component'
                    ).then((m) => m.EmployeeOverviewComponent),
            },
            {
                path: 'complete-profile/:id',
                loadComponent: () =>
                    import(
                        './features/employee-management/complete-profile/complete-profile-wizard/complete-profile-wizard.component'
                    ).then((m) => m.CompleteProfileComponent),
            },
            {
                path: 'edit-profile/:id',
                loadComponent: () =>
                    import(
                        './features/employee-management/employee-overview/edit-employee/edit-employee.component'
                    ).then((m) => m.EditEmployeeComponent),
            },
            {
                path: 'activities',
                loadComponent: () =>
                    import('./features/activities-management/activities-management.component').then(
                        (m) => m.ActivitiesManagementComponent,
                    ),
            },
            {
                path: 'activities/profile/:id',
                loadComponent: () =>
                    import(
                        './features/activities-management/activity-profile/activity-profile.component'
                    ).then((m) => m.ActivityProfileComponent),
            },
        ],
    },
    {
        path: 'error',
        loadChildren: async () => (await import('./common/routing/error-pages.module')).AppErrorPagesModule,
    },
    {
        path: 'signout',
        loadChildren: async () => (await import('./common/routing/sign-out.module')).AppSignOutModule,
    },
    { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
