import { Injectable } from '@angular/core';

import { CropData } from 'app/core/roles/models/crop.data.model';
import { BaseService } from 'app/features/employee-management/shared/base.service';

@Injectable({
    providedIn: 'root',
})
export class CropSubTypesService extends BaseService {
    constructor() {
        super();
        this.setBaseUrl(`${this.appConfigurationService.configuration.uris?.cropSubTypesService}`);
    }

    public getAll() {
        return this.getRequest<CropData>();
    }
}
