import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';

import { AppShellAreaService, AppShellAreaType, AppShellUtilitiesModule } from '@priva/appshell';
import { PrivaNotificationsModule, PrivaNotificationsService } from '@priva/components/notifications';
import { PrivaLocalizationService } from '@priva/localization';
import { PrivaToggleModule } from '@priva/utilities/toggle';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    imports: [AppShellUtilitiesModule, PrivaToggleModule, PrivaNotificationsModule, RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy {
    private notificationService = inject(PrivaNotificationsService);
    private appShellAreaService = inject(AppShellAreaService);
    private localization = inject(PrivaLocalizationService);
    public AppShellAreaType = AppShellAreaType;

    public hasNotificationBar = false;
    public hasPrimaryNav = true;

    private unsubscribe$: Subject<void> = new Subject();

    constructor() {
        this.localization.language.pipe(first()).subscribe();
    }

    public ngOnInit() {
        /* TODO: refactor these manual subscribes (use async pipe) */

        // Has notification bar
        this.notificationService.barVisibility
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((visible) => (this.hasNotificationBar = visible));

        // Has primary nav
        this.appShellAreaService
            .hasArea(AppShellAreaType.PrimaryNav)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((available: boolean) => (this.hasPrimaryNav = available));
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
