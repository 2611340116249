import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, of } from 'rxjs';

import { RoleFormatBE } from 'app/core/roles/models/role.model';

import { Employee } from '../models/employee.model';
import { EmployeeAvailability } from '../models/employeeAvailability';
import { EmployeeAvailabilityService } from '../shared/employee-availability.service';
import { EmployeeService } from '../shared/employee.service';
import { EmployeesActions } from './employees.actions';

@Injectable()
export class EmployeesEffects {
    private actions$ = inject(Actions);
    private employeeService = inject(EmployeeService);
    private employeeAvailabilityService = inject(EmployeeAvailabilityService);

    loadEmployees$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeesActions.loadEmployees),
            concatMap(() =>
                this.employeeService.getAll().pipe(
                    map((employees) => EmployeesActions.loadEmployeesSuccess({ employees })),
                    catchError((error) => of(EmployeesActions.loadEmployeesFailure({ message: error }))),
                ),
            ),
        ),
    );

    loadEmployeeById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeesActions.getEmployeeById),
            mergeMap((action) =>
                this.employeeService.getById(action.id).pipe(
                    map((employee) => {
                        const formattedEmployee = this.mapEmployeeRoles(employee);
                        return EmployeesActions.getEmployeeByIdSuccess({ employee: formattedEmployee });
                    }),
                    catchError((error) => of(EmployeesActions.getEmployeeByIdFailure({ message: error }))),
                ),
            ),
        ),
    );

    private mapEmployeeRoles(employeeFromBE: any): Employee {
        return {
            ...employeeFromBE,
            roles: employeeFromBE.roles.map((roleBE: RoleFormatBE) => ({
                id: roleBE.id,
                value: roleBE.name,
                label: roleBE.name,
            })),
        };
    }

    updateEmployee$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeesActions.updateEmployee),
            mergeMap((action) =>
                this.employeeService.update(action.employee.employeeId, action.employee).pipe(
                    map((employee) => EmployeesActions.updateEmployeeSuccess({ employee })),
                    catchError(() => of(EmployeesActions.updateEmployeeFailure())),
                ),
            ),
        ),
    );

    updateEmployeeData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeesActions.updateEmployeeData),
            mergeMap((action) =>
                this.employeeService.updateEmployeeData(action.employee.id, action.employee).pipe(
                    map((employee) => EmployeesActions.updateEmployeeDataSuccess({ employee })),
                    catchError(() => of(EmployeesActions.updateEmployeeDataFailure())),
                ),
            ),
        ),
    );

    loadEmployeeAvailabilityById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeesActions.getEmployeeAvailabilityById),
            mergeMap((action) =>
                this.employeeAvailabilityService.getById(action.id).pipe(
                    map((employeeAvailability) => {
                        return EmployeesActions.getEmployeeAvailabilityByIdSuccess({
                            employeeAvailability: employeeAvailability,
                        });
                    }),
                    catchError((error) => {
                        if (error.status === 404) {
                            const fallbackEmployeeAvailability = this.createFallbackEmployeeAvailability();
                            return of(
                                EmployeesActions.getEmployeeAvailabilityByIdSuccess({
                                    employeeAvailability: fallbackEmployeeAvailability,
                                }),
                            );
                        }
                        return of(EmployeesActions.getEmployeeAvailabilityByIdFailure({ message: error }));
                    }),
                ),
            ),
        ),
    );

    updateEmployeeAvailability$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeesActions.updateEmployeeAvailabilityById),
            mergeMap((action) =>
                this.employeeAvailabilityService
                    .updateEmployeeAvailabilityById(
                        action.employeeAvailability.employeeId!,
                        action.employeeAvailability,
                    )
                    .pipe(
                        map((employeeAvailability) =>
                            EmployeesActions.updateEmployeeDataAvailabilityByIdSuccess({
                                employeeAvailability,
                            }),
                        ),
                        catchError(() => of(EmployeesActions.updateEmployeeDataAvailabilityByIdFailure())),
                    ),
            ),
        ),
    );

    private createFallbackEmployeeAvailability(): EmployeeAvailability {
        return {
            contractHoursPerWeek: 0,
            isWorkingWeekRecurring: false,
            dateAvailability: '',
            weekAvailability: 0,
            yearAvailability: 0,
            workingWeeks: [],
            isActive: false,
        } as EmployeeAvailability;
    }
}
