import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';

import { AppConfigurationService } from './app-configuration.service';

@Injectable()
export class AppConfigurationResolver {
    private appConfiguration = inject(AppConfigurationService);
    private router = inject(Router);
    private configLoaded$: ReplaySubject<boolean>;

    constructor() {
        this.configLoaded$ = new ReplaySubject<boolean>(1);
    }

    public resolve(): Observable<boolean> {
        this.appConfiguration.configurationLoaded.subscribe((loaded: boolean) => {
            if (!loaded) {
                this.router.navigate(['error/not-authorized']);
            }
            this.configLoaded$.next(loaded);
            this.configLoaded$.complete();
        });
        this.appConfiguration.loadConfiguration();

        return this.configLoaded$.asObservable();
    }
}
