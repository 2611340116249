import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, of } from 'rxjs';

import { PreferredActivityService } from '../shared/preferred-activity.service';
import { PreferredActivitiesActions } from './preferred.activities.actions';

@Injectable()
export class PreferredActivitiesEffects {
    private actions$ = inject(Actions);
    private preferredActivityService = inject(PreferredActivityService);

    loadActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PreferredActivitiesActions.loadPreferredActivities),
            concatMap(() =>
                this.preferredActivityService.getAll().pipe(
                    map((activities) =>
                        PreferredActivitiesActions.loadPreferredActivitiesSuccess({ activities }),
                    ),
                    catchError((error) =>
                        of(PreferredActivitiesActions.loadPreferredActivitiesFailure({ message: error })),
                    ),
                ),
            ),
        ),
    );

    loadActivitiesByEmployId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PreferredActivitiesActions.getEmployeePreferredActivitiesById),
            mergeMap(({ id }) =>
                this.preferredActivityService.getById(id).pipe(
                    map((activities) =>
                        PreferredActivitiesActions.loadPreferredActivitiesByEmployeeIdSuccess({ activities }),
                    ),
                    catchError((error) =>
                        of(PreferredActivitiesActions.loadPreferredActivitiesFailure({ message: error })),
                    ),
                ),
            ),
        ),
    );

    updateActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PreferredActivitiesActions.updatePreferredActivities),
            mergeMap((action) =>
                this.preferredActivityService
                    .updateActivity(action.activities.employeeId, action.activities.activityList)
                    .pipe(
                        map(() => PreferredActivitiesActions.updatePreferredActivitiesSuccess()),
                        catchError((error) =>
                            of(PreferredActivitiesActions.updatePreferredActivitiesFailure(error)),
                        ),
                    ),
            ),
        ),
    );
}
