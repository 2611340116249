import { createReducer, on } from '@ngrx/store';

import { Activity } from 'app/core/activities/models/activity.model';
import { ActivitiesActions } from 'app/features/activities-management/state/activities.actions';
import { EmployeeInfo } from 'app/features/employee-management/models/employeeInfo.model';

export interface ActivitiesState {
    loading: boolean;
    removeEmployeeNotAllowed: string | null;
    loadingEmployees: boolean;
    loadingUnassignedEmployees: boolean;
    activities: Activity[];
    activity: Activity | null;
    errorMessage: string;
    undoEmployeeByActivityId: string | null;
    errorMessageSpecific: string;
    employees: EmployeeInfo[] | [];
    unassignedEmployees: EmployeeInfo[] | [];
    errorMessageCropSegment?: string | null;
}

const initalState: ActivitiesState = {
    loading: true,
    loadingEmployees: true,
    loadingUnassignedEmployees: true,
    removeEmployeeNotAllowed: null,
    undoEmployeeByActivityId: null,
    activities: [],
    employees: [],
    unassignedEmployees: [],
    activity: null,
    errorMessage: '',
    errorMessageSpecific: '',
    errorMessageCropSegment: '',
};

export const activitiesReducer = createReducer(
    initalState,
    on(ActivitiesActions.loadActivities, (state) => ({
        ...state,
        loading: true,
        loadingEmployees: true,
        loadingUnassignedEmployees: true,
        removeEmployeeNotAllowed: null,
        undoEmployeeByActivityId: null,
        activities: [] as Activity[],
        employees: [] as EmployeeInfo[],
        unassignedEmployees: [] as EmployeeInfo[],
        errorMessage: '',
        errorMessageSpecific: '',
        errorMessageCropSegment: '',
    })),
    on(ActivitiesActions.loadEmployees, (state) => ({
        ...state,
        loading: true,
        loadingEmployees: true,
        loadingUnassignedEmployees: false,
        removeEmployeeNotAllowed: null,
        undoEmployeeByActivityId: null,
        employees: [] as EmployeeInfo[],
        errorMessage: '',
        errorMessageSpecific: '',
        errorMessageCropSegment: '',
    })),
    on(ActivitiesActions.loadUnassignedEmployees, (state) => ({
        ...state,
        loading: false,
        loadingEmployees: false,
        loadingUnassignedEmployees: true,
        removeEmployeeNotAllowed: null,
        unassignedEmployees: [] as EmployeeInfo[],
        errorMessage: '',
        errorMessageSpecific: '',
        errorMessageCropSegment: '',
    })),
    on(ActivitiesActions.loadActivitiesSuccess, (state, { activities }) => ({
        ...state,
        loading: false,
        activities: activities,
        removeEmployeeNotAllowed: null,
        undoEmployeeByActivityId: null,
        errorMessage: '',
        errorMessageSpecific: '',
        errorMessageCropSegment: '',
    })),
    on(ActivitiesActions.loadActivitiesByIdSuccess, (state, { activity }) => ({
        ...state,
        loading: false,
        loadingEmployees: false,
        activities: state.activities.map((a: Activity) =>
            a.id === activity.id
                ? {
                      ...a,
                      cropSegment: activity.cropSegment,
                  }
                : a,
        ), // Update the CropSegment by activityId
        activity: activity,
        errorMessage: '',
        errorMessageSpecific: '',
        errorMessageCropSegment: '',
    })),
    on(ActivitiesActions.updateCropSegmentActivityByIdSuccess, (state, { activity }) => ({
        ...state,
        loading: false,
        loadingEmployees: false,
        activities: state.activities.map((a: Activity) =>
            a.id === activity.id
                ? {
                      ...a,
                      cropSegment: activity.cropSegment,
                  }
                : a,
        ), // Update the CropSegment by activityId
        activity: activity,
        errorMessage: '',
        errorMessageSpecific: '',
        errorMessageCropSegment: activity.cropSegment === null ? '' : null,
    })),
    on(ActivitiesActions.updateActivityRolesByIdSuccess, (state) => {
        return {
            ...state,
            loading: false,
            loadingEmployees: false,
            errorMessage: '',
            errorMessageSpecific: '',
            errorMessageCropSegment: '',
        };
    }),
    on(ActivitiesActions.loadActivitiesFailure, (state, { message }) => ({
        ...state,
        activities: [] as Activity[],
        errorMessage: message,
        errorMessageSpecific: '',
        loading: false,
        loadingEmployees: false,
    })),
    on(ActivitiesActions.updateCropSegmentActivityByIdFailure, (state, { message }) => ({
        ...state,
        errorMessageCropSegment: message,
        errorMessageSpecific: '',
        loading: false,
        loadingEmployees: false,
    })),
    on(ActivitiesActions.resetCropSegmentActivityByIdMessage, (state, { message }) => ({
        ...state,
        errorMessageCropSegment: message,
        errorMessageSpecific: '',
        loading: false,
        loadingEmployees: false,
    })),

    on(ActivitiesActions.loadEmployeesByActivityIdSuccess, (state, { employees }) => {
        return {
            ...state,
            employees: employees,
            errorMessage: '',
            errorMessageCropSegment: '',
            errorMessageSpecific: '',
            loading: false,
            loadingEmployees: false,
        };
    }),

    on(ActivitiesActions.loadUnassignedEmployeesByActivityIdSuccess, (state, { employees }) => ({
        ...state,
        unassignedEmployees: employees,
        errorMessage: '',
        errorMessageCropSegment: '',
        errorMessageSpecific: '',
        loading: false,
        loadingEmployees: false,
        loadingUnassignedEmployees: false,
    })),
    on(ActivitiesActions.loadEmployeesByActivityIdFailure, (state, { message }) => ({
        ...state,
        employees: [] as EmployeeInfo[],
        unassignedEmployees: [] as EmployeeInfo[],
        errorMessage: message,
        errorMessageSpecific: '',
        loading: false,
        loadingEmployees: false,
    })),
    on(ActivitiesActions.loadUnassignedEmployeesByActivityIdFailure, (state, { message }) => ({
        ...state,
        unassignedEmployees: [] as EmployeeInfo[],
        errorMessage: message,
        errorMessageSpecific: '',
        loading: false,
        loadingEmployees: false,
        loadingUnassignedEmployees: false,
    })),

    on(ActivitiesActions.removeEmployeeActivityByIdSuccess, (state, { employeeId }) => ({
        ...state,
        employees: state.employees.filter((e: EmployeeInfo) => e.id !== employeeId),
        errorMessage: '',
        errorMessageCropSegment: '',
        errorMessageSpecific: '',
        removeEmployeeNotAllowed: employeeId,
        loading: false,
        loadingEmployees: false,
    })),

    on(ActivitiesActions.addEmployeeToActivity, (state, { employee }) => ({
        ...state,
        employees: [...state.employees, employee],
        errorMessage: '',
        errorMessageCropSegment: '',
        errorMessageSpecific: '',
        loading: false,
        loadingEmployees: false,
    })),
    on(ActivitiesActions.removeEmployeeActivityById, (state) => ({
        ...state,
        removeEmployeeNotAllowed: null,
    })),
    on(ActivitiesActions.undoEmployeeActivityByIdSuccess, (state, { activityId }) => ({
        ...state,
        undoEmployeeByActivityId: activityId,
    })),
    on(ActivitiesActions.removeEmployeeActivityByIdFailureSpecific, (state, { message }) => ({
        ...state,
        errorMessageSpecific: message,
        removeEmployeeNotAllowed: '',
        loading: false,
        loadingEmployees: false,
    })),
);
