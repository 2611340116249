import { Injectable } from '@angular/core';

import { EmployeeInfo } from 'app/features/employee-management/models/employeeInfo.model';
import { BaseService } from 'app/features/employee-management/shared/base.service';

@Injectable({
    providedIn: 'root',
})
export class EmployeeActivityService extends BaseService {
    constructor() {
        super();
        this.setBaseUrl(`${this.appConfigurationService.configuration.uris?.employeeService}`);
    }

    public getEmployeesByActivityId(activityId: string) {
        const url = `${this.getBaseUrl()}/activity/${activityId}`;
        return this.getRequest<EmployeeInfo[]>(url);
    }

    public getUnassignedEmployeesByActivityId(activityId: string) {
        const url = `${this.getBaseUrl()}/unassigned/activity/${activityId}`;
        return this.getRequest<EmployeeInfo[]>(url);
    }
}
