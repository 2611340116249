import { Injectable } from '@angular/core';

import { SiteFormatBE } from 'app/core/sites/models/site.model';
import { BaseService } from 'app/features/employee-management/shared/base.service';

@Injectable({
    providedIn: 'root',
})
export class SitesService extends BaseService {
    constructor() {
        super();
        this.setBaseUrl(`${this.appConfigurationService.configuration.uris?.siteService}`);
    }

    public getAll() {
        return this.getRequest<SiteFormatBE[]>();
    }
}
