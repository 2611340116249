import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of } from 'rxjs';

import { RoleService } from '../services/role.service';
import { RolesActions } from './roles.actions';

@Injectable()
export class RolesEffects {
    private actions$ = inject(Actions);
    private rolesService = inject(RoleService);

    loadRoles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RolesActions.loadRoles),
            concatMap(() =>
                this.rolesService.getAll().pipe(
                    map((result) => {
                        const roles = result.map((role) => ({
                            id: role.id,
                            value: role.name,
                            label: role.name,
                        }));
                        return RolesActions.loadRolesSuccess({ roles });
                    }),
                    catchError((error) => of(RolesActions.loadRolesFailure({ message: error }))),
                ),
            ),
        ),
    );
}
