import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of } from 'rxjs';

import { CropSubTypesService } from 'app/core/roles/services/crop.sub.types.service';
import { CropDataActions } from 'app/features/activities-management/state/crop-data.actions';

@Injectable()
export class CropDataEffects {
    private actions$ = inject(Actions);
    private cropSubTypesService = inject(CropSubTypesService);

    loadCropData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CropDataActions.loadCropData),
            concatMap(() =>
                this.cropSubTypesService.getAll().pipe(
                    map((cropData) => {
                        return CropDataActions.loadCropDataSuccess({ cropData });
                    }),
                    catchError((error) => of(CropDataActions.loadCropDataFailure({ message: error }))),
                ),
            ),
        ),
    );
}
