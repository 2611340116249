import { createReducer, on } from '@ngrx/store';

import { CropType } from 'app/core/roles/models/crop.data.model';
import { CropDataActions } from 'app/features/activities-management/state/crop-data.actions';

export interface CropDataState {
    loading: boolean;
    cropTypes: CropType[] | [];
    errorMessage: string;
}

const initalState: CropDataState = {
    loading: true,
    cropTypes: [] as CropType[],
    errorMessage: '',
};

export const cropDataReducer = createReducer(
    initalState,
    on(CropDataActions.loadCropData, (state) => ({
        ...state,
        loading: true,
        cropTypes: [] as CropType[],
        errorMessage: '',
    })),
    on(CropDataActions.loadCropDataSuccess, (state, { cropData }) => ({
        ...state,
        loading: false,
        cropTypes: cropData.cropTypes,
    })),
    on(CropDataActions.loadCropDataFailure, (state, { message }) => ({
        ...state,
        cropTypes: [] as CropType[],
        errorMessage: message,
        loading: false,
    })),
);
