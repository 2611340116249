import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';

import { AppConfigurationService } from 'app/common/configuration';

export class BaseService {
    public appConfigurationService = inject(AppConfigurationService);

    private httpClient = inject(HttpClient);
    private baseUrl: string = '';

    public handleError(error: HttpErrorResponse) {
        // Handle the error here
        let errorMessage = 'An unknown error occurred!';
        if (error.error instanceof ErrorEvent) {
            // Client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side error
            errorMessage = error.message;
        }
        // Return a more user-facing error message, im returning in to string for mapping in to effects
        return throwError(() => errorMessage);
    }

    public getBaseUrl(): string {
        return this.baseUrl;
    }

    public setBaseUrl(url: string) {
        this.baseUrl = url;
    }

    /**
     *
     * @param url default or you can change from child
     */
    public getRequest<T>(url = this.getBaseUrl()) {
        return this.httpClient.get<T>(url).pipe(catchError(this.handleError));
    }

    /**
     *
     * @param url default or you can change from child
     * @param data body object data
     * @description generic POST request with data
     */
    public postRequest<T>(url = this.getBaseUrl(), data: any) {
        return this.httpClient.post<T>(url, data).pipe(catchError(this.handleError));
    }

    /**
     *
     * @param url default or you can change from child
     * @param data body object data
     * @description generic PUT request with data
     */
    public putRequest<T>(url = this.getBaseUrl(), data: any) {
        return this.httpClient.put<T>(url, data).pipe(catchError(this.handleError));
    }

    /**
     *
     * @param url default or you can change from child
     * @description generic PUT request with data
     */
    public deleteRequest<T>(url = this.getBaseUrl()) {
        return this.httpClient.delete<T>(url).pipe(catchError(this.handleError));
    }

    /**
     *
     * @param url default or you can change from child
     * @param data body object data
     * @description generic PUT request with data, Set Content-Type to text/plain because plain text is required
     */
    public putRequestPlainString<T>(url = this.getBaseUrl(), data: any) {
        // Wrap the string in quotes to form valid JSON
        return this.httpClient
            .put<T>(url, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } })
            .pipe(catchError(this.handleError));
    }
}
