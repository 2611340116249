import { Injectable } from '@angular/core';

import { BaseService } from 'app/features/employee-management/shared/base.service';

import { EmployeeAvailability } from '../models/employeeAvailability';

@Injectable({
    providedIn: 'root',
})
export class EmployeeAvailabilityService extends BaseService {
    constructor() {
        super();
        this.setBaseUrl(`${this.appConfigurationService.configuration.uris.employeeAvailabilityService}`);
    }

    public getById(id: string) {
        const url = `${this.getBaseUrl()}/${id}`;
        return this.getRequest<EmployeeAvailability>(url);
    }

    public updateEmployeeAvailabilityById(id: string, updatedEmployeeAvailability: EmployeeAvailability) {
        const url = `${this.getBaseUrl()}/${id}`;
        return this.putRequest<EmployeeAvailability>(url, updatedEmployeeAvailability);
    }
}
