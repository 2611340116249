import { APP_BASE_HREF, CommonModule } from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import {
    AppShellConfiguration,
    AppShellModule,
    AppShellUtilitiesModule,
    Preloader,
    PrimaryNavigationService,
} from '@priva/appshell';
import { PrivaAuthCurrentUserBffModule } from '@priva/auth/current-user';
import { PrivaAuthHttpBffModule, PrivaAuthHttpOptions } from '@priva/auth/http';
import { PrivaAuthSignoutModule } from '@priva/auth/signout';
import { PrivaDynamicModule } from '@priva/components/dynamic';
import { PrivaNotificationsModule } from '@priva/components/notifications';
import { provideDefaultChartTypes } from '@priva/dataviz/charts';
import { PrivaErrorPageHandlerService } from '@priva/error-pages';
import {
    PrivaLocalizationModule,
    PrivaLocalizationOptions,
    VersionBasedTranslationLoader,
} from '@priva/localization';
import { PrivaScrollbarModule } from '@priva/utilities/scrollbar';
import { PrivaScrollToModule } from '@priva/utilities/scrollto';
import { PrivaToggleModule } from '@priva/utilities/toggle';
import { PrivaWindowEventsModule } from '@priva/utilities/window-events';
import { provideDefaultTelemetry } from '@priva/variables';

import { ActivitiesEffects } from 'app/features/activities-management/state/activities-effects.service';
import { activitiesReducer } from 'app/features/activities-management/state/activities.reducer';
import { CropDataEffects } from 'app/features/activities-management/state/crop-data.effects';
import { cropDataReducer } from 'app/features/activities-management/state/crop-data.reducer';

import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import {
    AppConfigurationModule,
    AppConfigurationService,
    ErrorInterceptor,
} from './app/common/configuration';
import { AppNavigationModule, AppPrimaryNavigationService } from './app/common/navigation';
import { RolesEffects } from './app/core/roles/state/roles.effects';
import { rolesReducer } from './app/core/roles/state/roles.reducer';
import { SitesEffects } from './app/core/sites/state/sites.effects';
import { sitesReducer } from './app/core/sites/state/sites.reducer';
import { EmployeesEffects } from './app/features/employee-management/state/employees.effects';
import { employeesReducer } from './app/features/employee-management/state/employees.reducer';
import { PreferredActivitiesEffects } from './app/features/employee-management/state/preferred-activities-effects.service';
import { preferredActivitiesReducer } from './app/features/employee-management/state/preferred.activities.reducer';
import { AppEffects, appReducer } from './app/state';
import { buildInfo, environment } from './environments/environment';

const metaReducers: MetaReducer[] = !environment.production && environment.debugState ? [debug] : [];

/**
 * Preparation for State Management
 */

const STORE_CONFIG = {
    runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: true,
        strictActionSerializability: true,
    },
};

const devImports = !environment.production
    ? [
          StoreDevtoolsModule.instrument({
              maxAge: 250,
              logOnly: false,
          }),
      ]
    : [];

if (environment.production) {
    enableProdMode();
}

function getCustomAuthOptions(_configService: AppConfigurationService): PrivaAuthHttpOptions {
    const options = new PrivaAuthHttpOptions();
    options.bffConfig = { csrfValue: undefined };
    return options;
}

function getAppShellConfig(configService: AppConfigurationService): AppShellConfiguration {
    return AppShellConfiguration.from(configService.configuration);
}

export function getCustomLocalizationOptions(): PrivaLocalizationOptions {
    return {
        path: 'assets/translations',
        version: buildInfo.version,
        translationFileSuffix: '.json',
        availableLanguagesResource: 'assets/translations/locales.json',
        defaultLanguage: 'en-US',
    };
}

function getVersionBasedTranslationLoader(
    http: HttpClient,
    options: PrivaLocalizationOptions,
): VersionBasedTranslationLoader {
    return new VersionBasedTranslationLoader(http, options);
}

function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        // console.log('state: ', state);
        // console.log('action: ', action);
        return reducer(state, action);
    };
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            CommonModule,
            BrowserModule,
            PrivaAuthHttpBffModule.forRoot({
                provide: PrivaAuthHttpOptions,
                useFactory: getCustomAuthOptions,
                deps: [AppConfigurationService],
            }),
            AppRoutingModule,
            PrivaDynamicModule,
            AppNavigationModule.forRoot(),
            PrivaAuthCurrentUserBffModule.forRoot(),
            AppConfigurationModule.forRoot(),
            PrivaWindowEventsModule.forRoot(),
            PrivaScrollbarModule.forRoot(),
            AppShellModule.forRoot(
                {
                    provide: AppShellConfiguration,
                    useFactory: getAppShellConfig,
                    deps: [AppConfigurationService],
                },
                [
                    AppShellModule.provideDefaultFeatureToggleRouter(),
                    AppShellModule.provideDefaultHelpInfoService(),
                    { provide: PrimaryNavigationService, useClass: AppPrimaryNavigationService },
                ],
            ),
            AppShellUtilitiesModule,
            PrivaToggleModule.forRoot(),
            PrivaNotificationsModule.forRoot(),
            PrivaScrollToModule.forRoot(),
            PrivaAuthSignoutModule,
            /**
             * Localization, Storage & Translate configuration
             */
            PrivaLocalizationModule.forRoot({
                provide: PrivaLocalizationOptions,
                useFactory: getCustomLocalizationOptions,
            }),
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: getVersionBasedTranslationLoader,
                    deps: [HttpClient, PrivaLocalizationOptions],
                },
            }),
            /**
             * StateManagement, NgRx Store & Effects
             */
            StoreModule.forRoot(
                {
                    app: appReducer,
                    router: routerReducer,
                    employees: employeesReducer,
                    roles: rolesReducer,
                    sites: sitesReducer,
                    activities: activitiesReducer,
                    preferredActivities: preferredActivitiesReducer,
                    cropData: cropDataReducer,
                },
                { metaReducers, ...STORE_CONFIG },
            ),
            EffectsModule.forRoot([
                AppEffects,
                EmployeesEffects,
                RolesEffects,
                SitesEffects,
                PreferredActivitiesEffects,
                ActivitiesEffects,
                CropDataEffects,
            ]),
            StoreRouterConnectingModule.forRoot(),
            ...devImports,
        ),
        { provide: APP_BASE_HREF, useValue: '/' },
        PrivaErrorPageHandlerService,
        provideHttpClient(withInterceptorsFromDi()),
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        provideDefaultTelemetry(),
        provideDefaultChartTypes(),
        provideAnimations(),
    ],
})
    .then(() => {
        // trigger preloader
        if (Preloader.appBootstrap) {
            Preloader.appBootstrap();
        }
    })
    /* eslint-disable-next-line no-console */
    .catch((err) => console.log(err));
