import { Injectable } from '@angular/core';

import { BaseService } from 'app/features/employee-management/shared/base.service';

import { RoleFormatBE } from '../models/role.model';

@Injectable({
    providedIn: 'root',
})
export class RoleService extends BaseService {
    constructor() {
        super();
        this.setBaseUrl(`${this.appConfigurationService.configuration.uris?.roleService}`);
    }

    public getAll() {
        return this.getRequest<RoleFormatBE[]>();
    }
}
