import { Injectable } from '@angular/core';

import { BaseService } from 'app/features/employee-management/shared/base.service';

import { EmployeeFormatBE } from '../models/employee.model';
import { EmployeeData } from '../models/employeeData.model';
import { EmployeeList } from '../models/EmployeeList.model';
import { UpdatedEmployee } from '../models/updatedEmployee.model';

@Injectable({
    providedIn: 'root',
})
export class EmployeeService extends BaseService {
    constructor() {
        super();
        this.setBaseUrl(`${this.appConfigurationService.configuration.uris?.employeeService}`);
    }

    public getAll() {
        return this.getRequest<EmployeeList>();
    }

    public getById(id: string) {
        const url = `${this.getBaseUrl()}/${id}`;
        return this.getRequest<EmployeeFormatBE>(url);
    }

    public update(id: string, updatedEmployee: UpdatedEmployee) {
        const url = `${this.getBaseUrl()}/${id}`;
        return this.putRequest<UpdatedEmployee>(url, updatedEmployee);
    }

    public updateEmployeeData(id: string, updatedEmployee: EmployeeData) {
        const url = `${this.getBaseUrl()}/${id}/employeeData`;
        return this.putRequest<EmployeeData>(url, updatedEmployee);
    }
}
