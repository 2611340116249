import { Injectable } from '@angular/core';

import { Activity } from 'app/core/activities/models/activity.model';
import { BaseService } from 'app/features/employee-management/shared/base.service';

@Injectable({
    providedIn: 'root',
})
export class ActivityService extends BaseService {
    constructor() {
        super();
        this.setBaseUrl(`${this.appConfigurationService.configuration.uris?.activityService}`);
    }

    public getAll() {
        return this.getRequest<Activity[]>();
    }

    public getById(activityId: string) {
        const url = `${this.getBaseUrl()}/${activityId}`;
        return this.getRequest<Activity>(url);
    }

    public updateActivityById(activity: Activity) {
        const url = `${this.getBaseUrl()}/${activity.id}`;
        return this.putRequest<Activity>(url, activity);
    }

    public updateActivityRolesById(activityId: string, roles: string[]) {
        const url = `${this.getBaseUrl()}/${activityId}/roles`;
        return this.putRequest<Activity>(url, roles);
    }

    public updateActivitySegmentByActivityId(activityId: string, segmentId: string | null) {
        const url = `${this.getBaseUrl()}/${activityId}`;
        return typeof segmentId === 'string'
            ? this.putRequestPlainString<Activity>(url, segmentId)
            : this.putRequest<Activity>(url, segmentId);
    }
}
