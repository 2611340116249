import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, of, switchMap } from 'rxjs';

import { ActivityService } from 'app/features/activities-management/services/activity.service';
import { EmployeeActivityService } from 'app/features/activities-management/services/employee-activity.service';
import { ActivitiesActions } from 'app/features/activities-management/state/activities.actions';
import { PreferredActivityService } from 'app/features/employee-management/shared/preferred-activity.service';

@Injectable()
export class ActivitiesEffects {
    private actions$ = inject(Actions);
    private activityService = inject(ActivityService);
    private employeeActivityService = inject(EmployeeActivityService);
    private preferredActivityService = inject(PreferredActivityService);

    loadActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.loadActivities),
            concatMap(() =>
                this.activityService.getAll().pipe(
                    map((activities) => ActivitiesActions.loadActivitiesSuccess({ activities })),
                    catchError((error) => of(ActivitiesActions.loadActivitiesFailure({ message: error }))),
                ),
            ),
        ),
    );

    loadActivitiesByEmployeeId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.getActivityById),
            mergeMap(({ activityId }) =>
                this.activityService.getById(activityId).pipe(
                    map((activity) => ActivitiesActions.loadActivitiesByIdSuccess({ activity })),
                    catchError((error) => of(ActivitiesActions.loadActivitiesFailure({ message: error }))),
                ),
            ),
        ),
    );

    removeEmployeeFromActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.removeEmployeeActivityById),
            mergeMap(({ activityId, employeeId }) => {
                const id = employeeId;
                return this.preferredActivityService
                    .removeEmployeePreferredActivity(employeeId, activityId)
                    .pipe(
                        map(() =>
                            ActivitiesActions.removeEmployeeActivityByIdSuccess({
                                employeeId: id,
                            }),
                        ),
                        catchError((error) => {
                            if (error.includes('422')) {
                                return of(
                                    ActivitiesActions.removeEmployeeActivityByIdFailureSpecific({
                                        message: 'Not allowed for this operation',
                                    }),
                                );
                            } else {
                                return of(
                                    ActivitiesActions.removeEmployeeActivityByIdFailure({ message: error }),
                                );
                            }
                        }),
                    );
            }),
        ),
    );

    preferredActivitiesEmployees$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.addUnassignEmployeesToActivity),
            mergeMap(({ activityId, activityName, employeeIds, undoAction }) => {
                const id = activityId;
                return this.preferredActivityService
                    .preferredActivitiesUnassignedEmployees(activityId, activityName, employeeIds)
                    .pipe(
                        map(() => {
                            if (undoAction) {
                                return ActivitiesActions.undoEmployeeActivityByIdSuccess({
                                    activityId: id,
                                });
                            }
                            return ActivitiesActions.loadEmployees({
                                activityId: id,
                            });
                        }),
                        catchError((error) => {
                            return of(ActivitiesActions.loadEmployeesByActivityIdFailure({ message: error }));
                        }),
                    );
            }),
        ),
    );

    loadEmployeesByActivityId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.loadEmployees),
            mergeMap(({ activityId }) =>
                this.employeeActivityService.getEmployeesByActivityId(activityId).pipe(
                    map((employees) => {
                        return ActivitiesActions.loadEmployeesByActivityIdSuccess({ employees, activityId });
                    }),
                    catchError((error) =>
                        of(ActivitiesActions.loadEmployeesByActivityIdFailure({ message: error })),
                    ),
                ),
            ),
        ),
    );

    loadUnassignedEmployeesByActivityId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.loadUnassignedEmployees),
            mergeMap(({ activityId, selectedRoles }) =>
                this.employeeActivityService.getUnassignedEmployeesByActivityId(activityId).pipe(
                    map((employees) => {
                        if (selectedRoles && selectedRoles.length > 0) {
                            employees = employees.filter((employee) =>
                                employee.roles.some((role) =>
                                    selectedRoles.some((selectedRole) => selectedRole.name === role),
                                ),
                            );
                        }
                        return ActivitiesActions.loadUnassignedEmployeesByActivityIdSuccess({ employees });
                    }),
                    catchError((error) =>
                        of(ActivitiesActions.loadUnassignedEmployeesByActivityIdFailure({ message: error })),
                    ),
                ),
            ),
        ),
    );

    updateActivityRolesById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.updateActivityRolesById),
            mergeMap(({ activityId, roles }) =>
                this.activityService.updateActivityRolesById(activityId, roles).pipe(
                    map(() => ActivitiesActions.updateActivityRolesByIdSuccess()),
                    catchError((error) =>
                        of(ActivitiesActions.updateCropSegmentActivityByIdFailure({ message: error })),
                    ),
                ),
            ),
        ),
    );

    updateActivitySegmentByActivityId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.updateCropSegmentActivityById),
            mergeMap(({ activityId, segmentId }) =>
                this.activityService.updateActivitySegmentByActivityId(activityId, segmentId).pipe(
                    // Since the update API returns 204, directly call getById to fetch updated activity
                    switchMap(() =>
                        this.activityService.getById(activityId).pipe(
                            map((activity) => {
                                return ActivitiesActions.updateCropSegmentActivityByIdSuccess({ activity });
                            }),
                            catchError((error) =>
                                of(ActivitiesActions.loadActivitiesFailure({ message: error })),
                            ),
                        ),
                    ),
                    catchError((error) =>
                        of(ActivitiesActions.updateCropSegmentActivityByIdFailure({ message: error })),
                    ),
                ),
            ),
        ),
    );
}
